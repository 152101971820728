import { defineStore } from 'pinia'
import { ref, watch } from 'vue'
import { http } from '@/helpers/http'
import { useIDBKeyval } from '@vueuse/integrations/useIDBKeyval'
import type { TTagHint, TNiche, TVariantsWithNicheImage, TLastTagImageUpdate } from '@/types/tags'
import type { TDropdownOption } from '@/components/form/DesDropdown.vue'

export const useTagsStore = defineStore('tags', () => {
  const hints = ref<TTagHint[]>([])
  const niches = ref<TNiche[]>([])
  const lastTagImageUpdates = ref<TLastTagImageUpdate[]>([])
  const variantsWithNicheImages = ref<TVariantsWithNicheImage[]>([])
  const nicheDropdownOptions = ref<TDropdownOption[]>([])
  const { data: dataUpdatesIDB, set: setUpdatesIDB } = useIDBKeyval<string>('lastTagImageUpdates', '')
  const { data: dataHintsIDB, set: setHintsIDB } = useIDBKeyval<string>('hints', '')
  const { data: dataNichesIDB, set: setNichesIDB } = useIDBKeyval<string>('niches', '')
  const { data: dataVariantNicheImagesIDB, set: setVariantNicheImagesIDB } = useIDBKeyval<string>(
    'variantNicheImages',
    '',
  )

  let fetched: boolean = false
  let nichesFetched: boolean = false
  let lastTagImageUpdatesFetched: boolean = false

  async function fetchTags() {
    if (fetched) {
      return
    }
    try {
      const res = await http.post<TTagHint[]>('/tags/hints')
      if (!res) throw new Error('Failed to fetch themes')
      hints.value = res
      fetched = true
    } catch {
      hints.value = []
    }
  }

  async function storeLastTagImageUpdatesInDbIfNotSaved() {
    if (!dataUpdatesIDB.value?.length) {
      await setUpdatesIDB(JSON.stringify(lastTagImageUpdates.value))
    }
  }

  async function fetchLastTagImageUpdates(): Promise<void> {
    if (lastTagImageUpdatesFetched) {
      return
    }
    try {
      const res: TLastTagImageUpdate[] | null = await http.get<TLastTagImageUpdate[]>('/get-last-tag-image-updates/')
      if (!res) throw new Error('Failed to fetch last tag image updates')
      lastTagImageUpdates.value = res
      lastTagImageUpdatesFetched = true
    } catch {
      lastTagImageUpdates.value = []
    }
  }

  function getLastUpdateEntryByTagName(entries: TLastTagImageUpdate[], tagName: string): TLastTagImageUpdate | null {
    const filteredEntries = entries.filter((item) => item.type === tagName)
    return filteredEntries?.length ? filteredEntries[0] : null
  }

  function isIDBNotActual(
    lastUpdateDB: TLastTagImageUpdate | null,
    lastUpdateIDB: TLastTagImageUpdate | null,
  ): boolean {
    if (!lastUpdateDB || !lastUpdateIDB) {
      return false
    }
    return lastUpdateDB.updated_at !== lastUpdateIDB.updated_at
  }

  async function actualizeIDBData(lastUpdateDB: TLastTagImageUpdate | null, key: string): Promise<void> {
    if (!lastUpdateDB) {
      return
    }

    try {
      const lastUpdates = dataUpdatesIDB.value
      const parsedUpdates = JSON.parse(lastUpdates)

      parsedUpdates.forEach((item: TLastTagImageUpdate) => {
        if (item.type === key) {
          item.updated_at = lastUpdateDB.updated_at
        }
      })

      await setUpdatesIDB(JSON.stringify(parsedUpdates))
    } catch {
      throw new Error('Failed to parsing last updates')
    }
  }

  async function fetchVariantNicheImages(templateId: number): Promise<void> {
    try {
      variantsWithNicheImages.value =
        (await http.post<TVariantsWithNicheImage[]>('/tags/variant_niche_images', { templateId })) ?? []
    } catch {
      throw new Error('Failed to fetch variant niche images')
    }
  }

  async function getDataFromLocalStorageOrDb(key: string, templateId?: number): Promise<void> {
    let valueFromIDB: string = ''

    if (key === 'hints') {
      valueFromIDB = dataHintsIDB.value
    } else if (key === 'niches') {
      valueFromIDB = dataNichesIDB.value
    } else if (key === 'variantNicheImages') {
      valueFromIDB = dataVariantNicheImagesIDB.value
    }

    const noIDBData = !valueFromIDB?.length
    const lastUpdates = dataUpdatesIDB.value
    const lastUpdateDB = getLastUpdateEntryByTagName(lastTagImageUpdates.value, key)
    const lastUpdateIDB = getLastUpdateEntryByTagName(JSON.parse(lastUpdates), key)
    const iDBNotActual = isIDBNotActual(lastUpdateIDB, lastUpdateDB)

    try {
      if (key === 'hints') {
        if (noIDBData || iDBNotActual) {
          await fetchTags()
          await setHintsIDB(JSON.stringify(hints.value))
        } else {
          hints.value = JSON.parse(valueFromIDB)
        }
      } else if (key === 'niches') {
        if (noIDBData || iDBNotActual) {
          await fetchNiches()
          await setNichesIDB(JSON.stringify(niches.value))
        } else {
          niches.value = JSON.parse(valueFromIDB)
          nicheDropdownOptions.value = niches.value
            .filter((niche) => niche?.tag_images.length > 0)
            .map((niche) => ({
              name: niche.name,
              label: niche.name,
            }))
        }
      } else if (key === 'variantNicheImages' && (noIDBData || iDBNotActual) && templateId) {
        await fetchVariantNicheImages(templateId)
        const objectToStore = {
          [templateId]: variantsWithNicheImages.value,
        }
        await setVariantNicheImagesIDB(JSON.stringify(objectToStore))
      } else if (key === 'variantNicheImages' && templateId) {
        const parsedData = JSON.parse(valueFromIDB)
        if (templateId in parsedData) {
          variantsWithNicheImages.value = JSON.parse(valueFromIDB)[templateId]
        } else {
          const entries = Object.keys(parsedData)
          //keep max 10 entries in indexedDB
          if (entries.length > 9) {
            const lastKey = entries[entries.length - 1]
            delete parsedData[lastKey]
          }
          await fetchVariantNicheImages(templateId)
          parsedData[templateId] = variantsWithNicheImages.value
          await setVariantNicheImagesIDB(JSON.stringify(parsedData))
        }
      }
      if (iDBNotActual) {
        await actualizeIDBData(lastUpdateDB, key)
      }
    } catch (error) {
      console.error('Error fetching or parsing data:', error)
    }
  }

  async function fetchNiches() {
    if (nichesFetched) {
      return
    }
    try {
      niches.value = await http.get<TNiche[]>('/tags/get_templates_tags_with_images')
      nicheDropdownOptions.value = niches.value
        .filter((niche) => niche?.tag_images.length > 0)
        .map((niche) => ({
          name: niche.name,
          label: niche.name,
        }))
      nichesFetched = true
    } catch {
      throw new Error('Failed to fetch niches')
    }
  }

  return {
    hints,
    niches,
    variantsWithNicheImages,
    nicheDropdownOptions,
    fetchTags,
    fetchVariantNicheImages,
    getDataFromLocalStorageOrDb,
    fetchLastTagImageUpdates,
    lastTagImageUpdates,
    storeLastTagImageUpdatesInDbIfNotSaved,
  }
})
